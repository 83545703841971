/**
 * We can have different ways of rendering markdown, based on different functions:
 */
import ReactMarkdown from 'react-markdown'

import RenderJobDescription from './render-job-description'

export type RenderMarkdownProps = {
    type: string
    content: string
}

export default function RenderMarkdown({ type, content, ...rest }: RenderMarkdownProps) {
    switch (type) {
        case 'JobDescription':
            return <RenderJobDescription content={content} {...rest} />

        default:
            return <ReactMarkdown>{content}</ReactMarkdown>
    }
}
