import { HTMLProps, ReactNode } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'

import { Heading, Text } from '@doist/marketist'

import { slugify } from 'services'

function Paragraph({ children }: { children: ReactNode & ReactNode[] }) {
    return <Text size="body-base">{children}</Text>
}

function Header({ children }: { children: ReactNode & ReactNode[] }) {
    const id = slugify(String(children))

    return (
        <Heading id={id} size="major-base" level="3">
            {children}
        </Heading>
    )
}

function ListItem({ children }: { children: ReactNode & ReactNode[] }) {
    return (
        <li>
            <Text size="body-base">{children}</Text>
        </li>
    )
}

function Image(attrs: HTMLProps<HTMLImageElement>) {
    return <img style={{ width: '100%' }} src={attrs.src} alt="" />
}

export default function RenderJobDescription(props: { content: string }) {
    return (
        <ReactMarkdown
            components={
                {
                    p: Paragraph,
                    h1: Header,
                    h2: Header,
                    h3: Header,
                    li: ListItem,
                    img: Image,
                } as Components
            }
        >
            {props.content}
        </ReactMarkdown>
    )
}
