import { default as slugifyService } from 'slugify'

export default function slugify(text) {
    /** Remove quotes from text */
    const sanitizedText = text.replace(/&quot;/g, '')
    const slugifiedText = slugifyService(sanitizedText, {
        lower: true,
        remove: /[&*+~.()'"!:@?/]/g,
    })

    return slugifiedText
}
