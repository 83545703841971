import { DefaultSeo } from 'next-seo'

/**
 * Represent the fallback SEO, should be only used in _app.js.
 */
export default function GlobalSeo() {
    const removeFromIndexing = process.env.NO_INDEX === 'true'
    const images = [
        { url: `${process.env.NEXT_PUBLIC_BASE_URL}/static/images/twitter-doist-card.jpg` },
    ]

    return (
        <DefaultSeo
            title="Doist"
            description="The Fully Remote Company Behind Todoist & Twist"
            openGraph={{
                images,
                type: 'website',
                site_name: 'Doist',
                url: process.env.NEXT_PUBLIC_BASE_URL,
            }}
            twitter={{
                site: '@doist',
                handle: '@doist',
                cardType: 'summary_large_image',
            }}
            dangerouslySetAllPagesToNoIndex={removeFromIndexing}
            dangerouslySetAllPagesToNoFollow={removeFromIndexing}
        />
    )
}
