import { createContext, useState } from 'react'

import { UserContext as MarketistContext } from '@doist/marketist'

const UserContext = createContext()
export default UserContext

export function UserContextProvider({ children, webpSupport = false }) {
    const [context, setContext] = useState({
        webpSupport,
    })

    const update = (keyval) => {
        setContext({
            ...keyval,
            context,
        })
    }

    const value = {
        ...context,
        update,
    }

    return (
        <MarketistContext.Provider value={value}>
            <UserContext.Provider value={value}>{children}</UserContext.Provider>
        </MarketistContext.Provider>
    )
}
