import { NextSeo } from 'next-seo'

export default function Seo({
    title = '',
    description = '',
    noindex = false,
    nofollow = false,
    ...rest
}) {
    return (
        <NextSeo
            title={title}
            description={description}
            openGraph={{ title, description, ...rest }}
            noindex={noindex}
            nofollow={nofollow}
        />
    )
}
