/**
 * Cache in memory.
 */
let cacheObject = {}

const cache = {
    keys: {
        /** A list of all the cache keys, so that they do not conflict */
        WORKABLE_OPEN_ROLES: 'WORKABLE_OPEN_ROLES',
        WORKABLE_JOB: 'WORKABLE_JOB',
        /** Team page */
        TEAM_PAGE: 'TEAM_PAGE',
        PRODUCT_UPDATES: 'PRODUCT_UPDATES',
        GIVING_BACK: 'GIVING_BACK',
    },
    set: (key, val) => {
        cacheObject[key] = val
    },
    get: (key) => {
        return cacheObject[key]
    },
    /** Cache is automatically reset if query includes "refresh=true". Implemented in _app.js */
    reset: () => {
        cacheObject = {}
    },
}

export default cache
