/**
 * A component that serves to:
 *
 * 1. display menu items according to headings
 * 2. highlight menu item when its top scrolled past the middle of screen
 * 3. scroll to corresponding sections with id={slug} when menu item is clicked
 */

import { useEffect, useState } from 'react'

import { Link } from '@doist/marketist'

import classnames from 'clsx'

import css from './side-scroll-nav.module.css'

function SideScrollNav({ headings = [] }: { headings: { slug: string; text: string }[] }) {
    const [firstHeading] = headings
    const [activeSlug, setActiveSlug] = useState(firstHeading.slug)

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSlug(entry.target.id)
                    }
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            },
        )

        headings.forEach((heading) => {
            const element = document.getElementById(heading.slug)
            if (element) {
                observer.observe(element)
            }
        })

        return () => {
            headings.forEach((heading) => {
                const element = document.getElementById(heading.slug)
                if (element) {
                    observer.unobserve(element)
                }
            })
        }
    }, [headings])

    return (
        <ul className={css.sideScrollNav}>
            {headings.map(({ text, slug }) => (
                <li key={slug} className={classnames({ [css.active]: slug === activeSlug })}>
                    <Link href={`#${slug}`} size="body-sm">
                        {text}
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default SideScrollNav
