import { BrowserOptions, NodeOptions } from '@sentry/nextjs'

const sentryOptions: NodeOptions | BrowserOptions = {
    dsn: 'https://50bf43f55485459faec756d938677ef7@o476415.ingest.sentry.io/5530282',
    tracesSampleRate: 0,
    environment: process.env.SENTRY_ENV,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: [
        'extension',
        'window.__pad.performLoop',
        // https://github.com/Doist/Todoist-Landing-Pages/issues/2330
        // Likely culprit is Microsoft link checking or a similar service
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    ],
}

export { sentryOptions }
