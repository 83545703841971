/** Imports Marketist CSS */
import '@doist/marketist/dist/assets/style.css'

import 'styles/fonts.css'
import 'styles/global.css'

import { NextContextProvider, Theme, ThemeProvider, URLContext } from '@doist/marketist'

import { GlobalSeo } from 'components'
import App from 'next/app'
import Head from 'next/head'
import NextImage from 'next/image'
import { UserContextProvider } from 'services/contexts'

const nextProviderValues = {
    components: {
        head: Head,
        image: NextImage,
    },
    // The list can be found in Cloudinary settings:
    // - https://console.cloudinary.com/settings/c-27ba5991496dab1985e57b4accc6fb/security
    cloudinaryAllowedDomains: [
        'doist.com',
        'doist.net',
        'todoist.com',
        'todoist.net',
        'twist.com',
        'doist.dev',
        'doist-emails.com',
        'dcff1xvirvpfp.cloudfront.net',
        'images.ctfassets.net',
        'blog.doist.com',
        'tdinspiration.wpengine.com',
        'local.todoist.com',
        'get.todoist.help',
        'lh6.googleusercontent.com',
        'pbs.twimg.com',
        'vercel.app',
        'us-central1.run.app',
    ],
}

class LandingPagesApp extends App {
    render() {
        const { Component, pageProps } = this.props

        return (
            <>
                <Head>
                    <meta
                        name="facebook-domain-verification"
                        content={process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION}
                    />
                </Head>
                <URLContext.Provider value={pageProps.absoluteUrl}>
                    <ThemeProvider theme={Theme.doist}>
                        <NextContextProvider value={nextProviderValues}>
                            <UserContextProvider webpSupport={pageProps.webpSupport}>
                                <GlobalSeo />
                                <Component {...pageProps} />
                            </UserContextProvider>
                        </NextContextProvider>
                    </ThemeProvider>
                </URLContext.Provider>
            </>
        )
    }
}

export default LandingPagesApp
