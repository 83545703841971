import { useContext } from 'react'

import { UserContext } from '@doist/marketist'

interface LazyBackgroundImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string
    webp?: string
    children: React.ReactNode
}

// Component that load image asynchronously
export function LazyBackgroundImage({ src, webp, children, ...rest }: LazyBackgroundImageProps) {
    const { webpSupport } = useContext(UserContext)
    const resolvedSrc = webp && webpSupport ? webp : src

    return (
        <div style={{ backgroundImage: `url(${resolvedSrc})` }} {...rest}>
            {children}
        </div>
    )
}
