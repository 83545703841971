import { useRemoveNavigation } from 'services/contexts/navigation-context'

import Footer from './footer'
import { Header } from './header'

type LayoutProps = {
    children: React.ReactNode
    footerBackgroundColor?: string
    footerHairlineColor?: string
}

const Layout = ({ children, footerBackgroundColor, footerHairlineColor, ...rest }: LayoutProps) => {
    const removeNavigation = useRemoveNavigation()

    return (
        <div {...rest}>
            {removeNavigation ? null : <Header />}
            {children}
            {removeNavigation ? null : (
                <Footer
                    backgroundColor={footerBackgroundColor}
                    hairlineBackgroundColor={footerHairlineColor}
                />
            )}
        </div>
    )
}

export default Layout
