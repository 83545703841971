import { Container } from '@doist/marketist'

import cn from 'clsx'

import type { ContainerProps } from '@doist/marketist/dist/components/Container/Container'

interface SectionContainerProps extends ContainerProps {
    /** @deprecated */
    heroSection?: boolean
    /** @deprecated */
    section?: boolean
}

/**
 * SectionContainer is intended to be a temporary component during the design system update.
 * Avoid using this component, instead opt for the Container component from Marketist.
 */
export function SectionContainer({
    heroSection,
    section,
    className,
    ...rest
}: SectionContainerProps) {
    return (
        <Container
            className={cn(className, {
                'pb-48 sm-pb-64 md-pb-96': section,
                'pbs-48 pbe-48 sm-pbe-64 md-pbs-64 md-pbe-96': heroSection,
            })}
            {...rest}
        />
    )
}
