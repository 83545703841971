import { Footer, Text } from '@doist/marketist'

type DSTFooterProps = {
    backgroundColor?: string
    hairlineBackgroundColor?: string
}

export default function DSTFooter(props: DSTFooterProps) {
    const sections = [
        {
            title: 'Products',
            links: [
                { title: 'Todoist', href: 'https://todoist.com' },
                { title: 'Twist', href: 'https://twist.com' },
            ],
        },
        {
            title: 'Company',
            links: [
                { title: 'About Us', href: '/about-us' },
                { title: 'Careers', href: '/careers' },
                { title: 'Team', href: '/team' },
                { title: 'Press', href: '/press' },
                { title: 'Blog', href: '/blog' },
            ],
        },
    ]
    return (
        <Footer
            title={
                <Text size="body-base" weight="medium">
                    Empowering people with simple yet powerful tools.
                </Text>
            }
            columnLinks={sections}
            bottomLinks={[
                { title: 'Privacy', href: '/privacy' },
                { title: 'Terms', href: '/terms-of-service' },
            ]}
            socialLinks={[
                {
                    service: 'twitter',
                    url: 'https://twitter.com/doist',
                },
                {
                    service: 'youtube',
                    url: 'https://www.youtube.com/user/DoistApps',
                },
                {
                    service: 'instagram',
                    url: 'https://www.instagram.com/todoistofficial/',
                },
                { service: 'linkedin', url: 'https://www.linkedin.com/company/doist/' },
            ]}
            {...props}
        />
    )
}
